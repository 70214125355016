import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { lightSubtle, subtle } from '../../constants/theme';

const Staff = ({ staff }) => (
  <StyledArticle>
    {staff.map((staffMember, key) => (
      <StaffMember item={staffMember} key={key} />
    ))}
  </StyledArticle>
);

const StyledArticle = styled.article`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  margin-left: -5rem;
  align-items: flex-start;
`;

Staff.propTypes = {
  staff: PropTypes.array.isRequired,
};

export default Staff;

const StaffMember = ({ item: { name, role, bioShort, bioFull, icon } }) => {
  const [reveal, setReveal] = useState(false);
  const [resize, setResize] = useState(false);
  const iconContainerRef = useRef(null);
  const mainContainerRef = useRef(null);
  const bioFullRef = useRef(null);
  const isWrapped = () =>
    iconContainerRef.current.getBoundingClientRect().top + window.pageYOffset <
    mainContainerRef.current.getBoundingClientRect().top + window.pageYOffset;
  useEffect(
    () =>
      setResize({
        isWrapped: isWrapped(),
        reveal: reveal,
        textHeight: bioFullRef.current.scrollHeight,
      }),
    [reveal]
  );
  return (
    <StaffMemberContainer {...resize}>
      <div className="icon-container" ref={iconContainerRef}>
        <div className="icon" dangerouslySetInnerHTML={{ __html: icon }} />
        <p className="subtle">{role}</p>
      </div>
      <div className="main" ref={mainContainerRef}>
        <h4>{name}</h4>
        <p className="bio-short">{bioShort}</p>
        <p className="bio-full" ref={bioFullRef}>
          {bioFull}
        </p>
        <div
          className="reveal"
          style={{ visibility: `${bioFull ? 'visible' : 'hidden'}` }}
        >
          <button onClick={() => (reveal ? setReveal(false) : setReveal(true))}>
            {reveal ? 'Zobrazit méně ↑' : 'Zobrazit více ↓'}
          </button>
        </div>
      </div>
    </StaffMemberContainer>
  );
};

const StaffMemberContainer = styled.div`
  flex: 1 1 350px;
  box-sizing: border-box;
  margin-left: 5rem;
  margin-bottom: 2rem;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;
  border: 1px solid ${lightSubtle};
  padding: 1.5rem;
  transition: border 0.5s ease;

  .subtle {
    font-size: 13px;
    color: ${subtle};
    margin: 0.5rem 0 0;
    text-align: center;
  }
  .icon-container {
    overflow: hidden;
    width: 124px;
    height: 155px;
    opacity: 1;
    transition: all 0.2s 0.3s ease;
  }
  .bio-full {
    transition: all 0.2s 0.1s ease;
    max-height: 0;
    overflow: hidden;
    margin: 0;
  }

  .main {
    flex: 1 1 400px;
    padding: 2rem;
    text-align: justify;
    h4 {
      font-weight: 600;
    }
  }
  p {
    margin-top: 1.5rem;
  }
  .reveal {
    text-align: right;
    button {
      border: none;
      background: none;
      color: ${subtle};
      text-decoration: underline;
      cursor: pointer;
    }
  }
  ${({ reveal, isWrapped, textHeight }) =>
    reveal &&
    css`
      border-color: transparent;
      .icon-container {
        transition: all 0.2s 0.1s ease;
        opacity: 0;
        height: ${isWrapped ? '0' : '155px'};
        width: ${isWrapped ? '124px' : '0'};
      }
      .bio-full {
        max-height: ${textHeight}px;
        transition: all 0.2s 0.3s ease;
      }
    `}
`;

StaffMember.propTypes = {
  item: PropTypes.object.isRequired,
};
