import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { subtle } from 'constants/theme';
import { textDark } from '../../constants/theme';

const Info = ({ children }) => <StyledArticle>{children}</StyledArticle>;

const StyledArticle = styled.article`
  box-sizing: border-box;
  flex: 1 1 40%;
  display: flex;
  flex-flow: column nowrap;
  min-width: 280px;
  margin-bottom: 2rem;
  dl {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2rem;
    line-height: 150%;
    dt {
      flex: 1 1 30%;
      min-width: 152px;
      color: ${subtle};
    }
    dd {
      text-align: right;
      flex: 1 1 50%;
      min-width: 280px;
      a {
        color: ${textDark};
      }
    }
  }
  & .subtle {
    color: ${subtle};
  }

  p {
    margin-top: 2.5rem;
  }
`;

Info.propTypes = {
  children: PropTypes.string.isRequired,
};

export default Info;
