import styled from 'styled-components';
import { mainLeft, mainCenter, mainRight, greenDark } from 'constants/theme';

export const Container = styled.header`
  background: linear-gradient(
    45deg,
    ${mainCenter} 0%,
    ${mainRight} 100%
  );
  height: 100vh;
  max-height: 1200px;
  min-height: 750px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: center;

  .box {
    padding: 5rem 0;
    height: 100%;
    max-height: 700px;
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    text-align: center;
    max-width: 450px;
  }
  .title-box {
    display: flex;
    flex-flow: column nowrap !important;
    justify-content: space-evenly !important;
  }
  h1 {
    font-size: 3.2rem;
    padding: 0 15px 10px 15px;
    font-family: Nunito, sans-serif;
    text-transform: uppercase;
    margin-bottom: 4.5rem;
    border-bottom: 1px solid ${greenDark};
  }

  h2 {
    font-size: 3rem;
    line-height: 3.8rem;
    font-weight: normal;
    letter-spacing: -0.5px;
    margin: 3rem 0 4rem 0;
    max-width: 425px;
  }

  p {
    text-align: center;
    font-size: 1.85rem;
    letter-spacing: -0.3px;
    line-height: 2.4rem;
  }
  .box > div {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-bottom: 5rem;
  }
  svg {
    max-width: 100%;
  }
`;
