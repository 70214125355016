import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

import { Modal } from 'antd';
import 'antd/lib/modal/style/index.css';
import 'antd/lib/table/style/index.css';
import { textDark, greenDark } from '../../constants/theme';

const InfoModal = ({ show, setShow, images }) => {
  const image = images.allImageSharp.nodes.find(
    ({ fluid }) => fluid.originalName === 'pt-dovolena-2024.jpg'
  );

  return (
    <Modal
      visible={show}
      mask={true}
      footer={null}
      bodyStyle={{
        border: `2px solid ${greenDark}`,
      }}
      onCancel={() => setShow(false)}
    >
      <Container>
        <h4>Čerpání dovolené</h4>
        <Img fluid={image ? image.fluid : {}} alt="Čerpání dovolené 2024" />
      </Container>
    </Modal>
  );
};

export default InfoModal;

const Container = styled.div`
  h4 {
    font-weight: 600;
  }
  p,
  h4 {
    margin: 12px 0 !important;
    font-size: 16px;
    color: ${textDark};
  }
`;
