import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { lightAccent, darkText, mainRight, greenDark } from 'constants/theme';
import { textDark } from '../../constants/theme';
import scrollTo from 'gatsby-plugin-smoothscroll';

const Button = ({ scrollTarget, children }) => (
  <Container onClick={() => scrollTo(scrollTarget)}>{children}</Container>
);

const Container = styled.button`
  background: ${lightAccent};
  color: ${darkText};
  display: block;
  padding: 10px 30px;
  font-size: 11.5px;
  border: 1px solid ${mainRight};
  border-radius: 3px;
  text-decoration: none;
  color: ${textDark};
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;

  &:hover {
    border: 1px solid ${greenDark};
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
  }
  &:focus {
    border: 1px solid ${greenDark};
  }
`;

Button.propTypes = {
  scrollTarget: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

export default Button;
