import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './header.css';
import Triptych from 'images/triptych.svg';
import LogoLarge from 'images/logoLarge.svg';

const Header = ({ children }) => (
  <Container>
    <div className="box">
      <LogoLarge alt="Pediatr Tenora logo" />
      <div className="title-box">
        <h2>Ordinace dětského lékaře v Kostelci na Hané</h2>
        <p>Přijímáme pacienty od těch nejmladších do 19 let. Rádi se s Vámi setkáme v naší ordinaci na ul. Leginonářská v Kostelci na Hané</p>
      </div>
      {children}
      <Triptych />
    </div>
  </Container>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Header;
